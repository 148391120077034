import React from 'react';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from '@emotion/styled';
import Layout from '../components/layout';
import Seo from '../components/seo';

const Container = styled.main`
  padding: 35px;
  max-width: 880px;
  margin: 25px auto;
  border-left: 2px solid ${({ theme }) => theme.colors.blue};
`;

const Document = ({ data, pageContext }) => {
  return (
    <Layout>
      <Seo title={data.contentfulDocument.documentType} />
      <Container role="main">
        {documentToReactComponents(
          JSON.parse(data.contentfulDocument.documentText.raw),
        )}
      </Container>
    </Layout>
  );
};

export const documentQuery = graphql`
  query document($id: String!) {
    contentfulDocument(id: { eq: $id }) {
      id
      documentType
      documentText {
        raw
      }
    }
  }
`;

export default Document;
